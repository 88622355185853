<template>
  <div>
    <EditDialog
      width="70%"
      :isReturn="true"
      @closeFn="$emit('update:isShow', false)"
      :isShow.sync="invoiceIsShow"
    >
      <div slot="title" class="title">
        {{ applyType == 'editUnsignedContracts' ? '补签项目与合同' : '补合同' }}
      </div>
      <div slot="content" class="content" v-loading="loading" max-width="400">
        <el-form :model="form" ref="form" :rules="rulesInvoice">
          <el-descriptions :column="7" border direction="vertical" style="width: 100%">
            <template v-if="applyType == 'editUnsignedContracts'">
              <el-descriptions-item :span="4">
                <template slot="label">
                  <span style="color: red">*</span>
                  项目名称
                </template>
                <el-form-item ref="projectName" prop="projectName">
                  <ChoiceProject
                    :options="form"
                    :projectSelsectId="projectSelsectId"
                    @choiceDataChange="choiceProject"
                  >
                    <el-input
                      slot="button"
                      :readonly="true"
                      style="cursor: pointer; margin-bottom: 8px"
                      v-model="form.projectName"
                      placeholder="请选择项目名称"
                    ></el-input>
                    <template slot="tableColumn-left">
                      <el-table-column
                        align="center"
                        prop="projectName"
                        :show-overflow-tooltip="false"
                        min-width="320"
                        label="项目名称"
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        min-width="220"
                        :show-overflow-tooltip="false"
                        prop="customersUnit"
                        label="业主单位"
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        prop="theirDeptName"
                        label="所属部门"
                      ></el-table-column>
                    </template>
                  </ChoiceProject>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 项目分管高管 </template>
                <el-form-item ref="fzManagerName" prop="fzManagerName">
                  {{ form.fzManagerName }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 项目所属部门 </template>
                <el-form-item ref="deptClassifyName" prop="deptId" style="width: 100%">
                  {{ form.deptClassifyName }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 项目来源 </template>
                <el-form-item ref="projectSource" label-width="0px" prop="projectSource">
                  {{ form.projectSource | dict(projectSourceList) }}
                </el-form-item>
              </el-descriptions-item>
            </template>

            <el-descriptions-item :span="4">
              <template slot="label">
                <span style="color: red">*</span>
                合同名称
              </template>

              <el-form-item ref="agreementName" prop="agreementName">
                <ChoiceContract
                  :options="form"
                  :agreementSelsectId="agreementSelsectId"
                  @choiceDataChange="choiceContract"
                >
                  <el-input
                    slot="button"
                    :readonly="true"
                    style="cursor: pointer; margin-bottom: 8px"
                    v-model="form.agreementName"
                    placeholder="请选择合同名称"
                  ></el-input>

                  <template slot="tableColumn-left">
                    <el-table-column
                      align="center"
                      prop="agreementName"
                      min-width="320"
                      :show-overflow-tooltip="false"
                      label="合同名称"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      min-width="220"
                      :show-overflow-tooltip="false"
                      prop="customersUnit"
                      label="业主单位"
                    ></el-table-column>
                  </template>
                </ChoiceContract>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label"> 合同编号 </template>
              <el-form-item ref="agreementNum" label-width="0px" prop="agreementNum">
                {{ form.agreementNum }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label"> 合同总金额 （元） </template>
              <el-form-item ref="actualContractAmount" prop="actualContractAmount">
                {{ form.actualContractAmount | applyAmount }}
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>
      </div>
      <div slot="footer" class="footer">
        <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        <el-button type="success" @click="invoiceSubmit">确定</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
import { formLocationError } from '@/util/common'
export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    ChoiceContract: () => import('@/components/invoice/ChoiceContract.vue'),
    ChoiceProject: () => import('@/components/invoice/ChoiceProject.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    applyType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      invoiceIsShow: false,
      form: {
        projectName: null,
        projectId: null,
        fzManagerName: null,
        deptFzManagerId: null,
        deptClassifyName: null,
        deptClassifyId: null,
        projectSource: null,
        agreementName: null,
        agreementId: null,
        agreementNum: null,
        actualContractAmount: null,
      },
      rulesInvoice: {
        projectName: [
          {
            required: true,
            message: '请选择项目名称',
            trigger: ['blur', 'change'],
          },
        ],
        agreementName: [
          {
            required: true,
            message: '请选择合同名称',
            trigger: ['blur', 'change'],
          },
        ],
      },
      projectSourceList: [],
      projectSelsectId: null,
      agreementSelsectId: null,
    }
  },
  computed: {},
  watch: {
    isShow: {
      immediate: true,
      handler: function (val) {
        this.invoiceIsShow = val
        if (val && this.applyType == 'convertProjectToContractSigned') {
          this.form.projectId = this.options.projectId
        }
      },
    },
  },
  created() {
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE_COLLECT', true)
      .then(res => {
        this.projectSourceList = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  mounted() {},
  methods: {
    // 保存
    invoiceSubmit() {
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          this.form.id = this.options.id
          this.form.invoiceApplicationType = this.options.invoiceApplicationType
          let obj = {}
          if (this.applyType == 'convertProjectToContractSigned') {
            obj.id = this.form.id
            obj.agreementName = this.form.agreementName
            obj.agreementId = this.form.agreementId
            obj.actualContractAmount = this.form.actualContractAmount
            obj.agreementNum = this.form.agreementNum
            obj.invoiceApplicationType = this.form.invoiceApplicationType
          } else {
            obj = this.form
          }
          this.loading = true
          this.$api.invoice[this.applyType](obj)
            .then(res => {
              this.$message({
                message: '补充成功',
                type: 'success',
              })

              this.loading = false
              this.$emit('invoiceDetailsFn')
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        } else {
          if (this.$refs) {
            formLocationError(object, this.$refs)
            return false
          }
        }
      })
    },
    // 选择项目
    choiceProject(r) {
      this.form.projectId = r.id
      this.form.projectName = r.projectName //项目名
      this.form.deptClassifyName = r.deptClassifyName //所属部门名称
      this.form.deptClassifyId = r.deptClassifyId //所属部门id
      this.form.fzManagerName = r.deptFzManagerName //分管高管
      this.form.deptFzManagerId = r.deptFzManagerId //分管高管id
      this.form.projectSource = r.projectSource //项目来源
      this.form.agreementId = null
      this.form.agreementName = null
      this.form.agreementNum = null
      this.form.actualContractAmount = null
      this.projectSelsectId = r.id
      this.agreementSelsectId = null //合同选中
    },
    choiceContract(r) {
      this.agreementSelsectId = r.id
      this.form.agreementId = r.id
      this.form.agreementName = r.agreementName
      this.form.agreementNum = r.agreementNum
      this.form.actualContractAmount = r.actualContractAmount
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
}
</script>
<style scoped lang="scss"></style>
